const host = 'https://www.equipmoney.com';
const apikey = document.currentScript.dataset.token || document.currentScript.getAttribute('token');
let widget;

const fontFile = new FontFace('Open Sans', 'url(https://cdn.jsdelivr.net/fontsource/fonts/open-sans:vf@latest/latin-wght-normal.woff2)', {weight: '300 800'});
const money = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, roundingMode: 'ceil'});

class Button extends HTMLElement {
    static observedAttributes = ['data-price', 'data-qty'];
    loader = [];

    connectedCallback() {
        Button.preview ||= fetch(`${host}/embed/preview.php?apikey=${apikey}`)
            .then(response => response.ok ? response.text() : Promise.reject('EquipMoney widget failed to load'))
            .catch(console.warn);

        document.fonts.add(fontFile);
        this.loader.push(fontFile.load());

        this.shadow = this.attachShadow({mode: 'closed'});
        this.addEventListener('click', this.#click.bind(this));
        Button.preview.then(this.#preview.bind(this));

        this.dataset.price ||= '';
        this.dataset.qty ||= '';
        this.dataset.product ||= '';
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.shadow && this.recalculate();
    }

    #preview(html) {
        if (!html) return;
        this.shadow.innerHTML = String(html);
        this.recalculate();

        this.shadow.querySelectorAll('link').forEach(link => {
            link.sheet || this.loader.push(new Promise(resolve => link.addEventListener('load', resolve)));
        });

        Promise.all(this.loader).then(() => {
            this.shadow.querySelector('main').style.opacity = null;
        });
    }

    #click(event) {
        event.stopPropagation();
        event.preventDefault();
        this.open();
    }

    open() {
        this.close();
        widget = document.createElement('equipmoney-widget');
        document.body.appendChild(widget);
        widget.open(this.dataset);
        return widget;
    }

    close() {
        widget?.close();
    }

    recalculate() {
        const main = this.shadow?.querySelector('main');
        if (!main?.dataset?.rate) return;
        const priceEl = this.shadow.querySelector('.price');

        const price = Number(this.dataset.price.replace(/[$,“”]+/g, ''));
        const qty = Number(this.dataset.qty) || 1;
        const rate = Number(main.dataset.rate);
        priceEl.innerHTML = money.format(price * qty * rate);
    }
}

class Widget extends HTMLElement {
    loader = [];

    connectedCallback() {
        this.loader.push(fontFile.load());
        this.shadow = this.attachShadow({mode: 'closed'});
        this.style.cssText = `position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: ${Number.MAX_SAFE_INTEGER}; transition: all .2s; opacity: 0;`;
        setTimeout(() => this.style.opacity = '');
    }

    open(dataset) {
        this.dialog = document.createElement('dialog');
        this.dialog.style.cssText += 'display: flex; justify-content: center; align-items: center; height: 100%; width: 100%; padding: 0; border: 0; background: rgba(0, 0, 0, 0.5);';
        this.dialog.addEventListener('click', event => event.target === this.dialog && this.close());
        this.shadow.appendChild(this.dialog);

        this.closeX = document.createElement('div');
        this.closeX.addEventListener('click', this.close.bind(this));
        this.closeX.className = 'text-primary m-3 absolute top-0 left-0 cursor-pointer text-3xl font-bold rounded-full';
        this.closeX.title = 'Close';
        this.closeX.innerHTML = '&#10006;';

        const params = new URLSearchParams({apikey, ...dataset});
        fetch(`${host}/embed/?${params}`)
            .catch(error => console.warn(error))
            .then(this.#embed.bind(this));

        const script = document.createElement('script');
        script.src = `${host}/build/js/embed.js`;
        this.loader.push(new Promise(resolve => script.addEventListener('load', resolve)));
        this.appendChild(script);
    }

    close() {
        this.addEventListener('transitionend', () => this.remove());
        this.style.opacity !== '0' ? this.style.opacity = '0' : this.remove();
    }

    async #embed(response: Response) {
        await Promise.all(this.loader);

        this.dialog.innerHTML = await response.text();
        this.dialog.querySelectorAll('script').forEach(link => link.remove());
        this.dialog.querySelectorAll('link').forEach(link => {
            this.loader.push(new Promise(resolve => link.addEventListener('load', resolve)));
        });

        const embed = this.dialog.querySelector('equipmoney-embed');
        embed.appendChild(this.closeX);

        Promise.all(this.loader).then(() => {
            window.addEventListener('resize', embed.resize.bind(embed));
            embed.resize();
        });
    }
}

customElements.get('equipmoney-button') || customElements.define('equipmoney-button', Button);
customElements.get('equipmoney-widget') || customElements.define('equipmoney-widget', Widget);
document.currentScript.remove();
